@import "../../styles/mixins";

h5 {
  font-size: 17px !important;
  font-weight: 400;
  line-height: 1.8em;
  font-family: "Poppins", sans-serif;
}

p {
  font-family: "Mulish", sans-serif;
}

.footer-section {
  background-color: #040c1a;
  padding: 50px 0px;
  height: 100%;

  img {
    margin-bottom: 20px;
    width: 152px;
  }

  h4 {
    color: #fff;
    font-weight: 600;
    font-family: "Poppins", sans-serif;
  }

  p {
    font-size: 14px;
    color: #e6e6e6;
  }

  .footer-link-wrapper ul {
    padding-left: 0rem;

    .footer-menu-link {
      padding: 0rem 0rem;
    }

    a {
      text-decoration: none;
      font-size: 14px;

      li {
        list-style: none;
        color: #e1e1e1;
        line-height: 1.8rem;
        transition: 0.3s ease;
        font-family: "Mulish", sans-serif;

        &:hover {
          color: #fd7800;
          transition: 0.3s ease;
        }
      }
    }
  }
  .footer-co-link{
    font-size: 14px;
  }
  .footer-co-link:hover{
    color: #fd7800;
    transition: 0.3s ease;
  }
  .footer-contact-wrapper {
    a{
      text-decoration: none;
      color: white;
    }
    ul {
      padding-left: 0rem;

      .footer-co-link {
        padding: 0rem 0rem;
      }

      li {
        list-style: none;
        color: #e1e1e1;
        line-height: 1.5rem;
        margin-bottom: 10px;
        transition: 0.3s ease;
        font-family: "Mulish", sans-serif;
        text-decoration: none;
        font-size: 14px;
        margin-top: 7px;
      }
      .footer-co-link li{
        &:hover {
          color: #fd7800;
          transition: 0.3s ease;
        }
      }
    }
  }

  .footer-social-media-wrapper {
    display: flex;

   
    .fo-social-icon {
      height: 30px;
      width: 30px;
      border-radius: 100px;
      margin-right: 23px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.3s ease-in-out;

      &:hover {
        box-shadow: 0 9px 13px #2c79ff8e;
        transform: translateY(-5px);
        transition: 0.3s ease-in-out;
        background-color: #2c78ff;

        &:hover svg {
          color: #ffffff;
          transition: 0.3s ease-in-out;
        }
      }

      @include tablet{
        margin-right: 10px;
      }

      svg {
        color: #040c1a;
        font-size: 14px;
      }
    }
  }
}

.footer-language_selector {
  display: none;
  @include mobile {
    display: block;
    .form-select {
      margin-top: 20px;
      border-radius: 2.25rem;
      font-family: "Poppins", sans-serif;
      padding: 0.6rem 2.25rem 0.6rem 0.75rem;
      font-size: 15px;
      color: #5a5a5a;
      border: 1px solid #707070;

      &:focus {
        border-color: #fd8b00;
        box-shadow: 0 0 0 0.25rem #fd8b003d;
      }

      option {
        margin-top: 10px;
        font-weight: normal;
        display: block;
        white-space: nowrap;
        min-height: 1.2em;
        padding: 0px 2px 1px;
        border-radius: 30px !important;
      }
    }
  }
}

.max-width-250 {
  max-width: 250px;
}