.features-hero {
  background-image: url(../../img/FeaturesHero.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  display: block;
  border-radius: 30px;
  text-align: center;
  position: relative;

  h1.vertical-center {
    position: absolute;
    color: white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.center-heading {
  h5 {
    padding: 20px 50px 30px 50px;
    color: #727272;
  }
}

.feature-card {
  background-color: #f8faff;
  height: 100%;
  width: 90%;
  text-align: center;
  border-radius: 22px;
  padding: 50px 25px;
  box-shadow: 0 0px 13px #9999993b;

  &:hover {
    background-color: #f8faff;
    transition: 0.5s ease-in-out;
  }
}

.feature-content-wrapper {
  img {
    height: 90px;
    width: 90px;
    margin-bottom: 20px;
  }
}

.feature-cat-wrapper {
  display: block;
  text-align: center;
  margin-top: 15px;
}

.feature-clm {
  padding: 40px 70px;
}

.fe-main-category a {
  text-decoration: none;
  color: inherit;
}

.fe-main-category a:hover .feature-card {
  text-decoration: none;
  color: #2c78ff;
}

.feature-li {
  background-color: #fff;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: stretch;
  text-align: center;
  border-radius: 40px;
  padding: 60px 35px;
  box-shadow: 0 0px 13px #9999993b;

  img {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }

  h5 {
    color: #2c78ff;
    font-weight: 400;
  }
}

.feature-cta {
  display: flex;
  justify-content: center;
  align-items: center;
}
