@import "../../../styles/mixins";

.Pricing-hero {
  background-image: url(../../../img/PricingHero.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  display: block;
  border-radius: 30px;
  text-align: center;
  position: relative;

  h1.vertical-center {
    position: absolute;
    color: white;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
  }
}

.pricing-wrapper {
  // overflow: hidden;
  border-radius: 22px;
  // position: relative;
  box-shadow: 0 0px 22px #9999993b;
  background-color: rgb(255, 255, 255);
}

.pricing-list {
  // background-color: #ffffff;
  padding: 15px 15px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 15px;
      text-align: left;
      // border-bottom: 1px solid #ddd;
      min-height: 58px;
      font-family: "Mulish", sans-serif;
      display: flex;
      flex-flow: row-reverse;
      justify-content: start;
      line-height: 1rem;
      h3 {
        font-weight: 600;
        color: #2c78ff;
        margin-bottom: 15px;
      }

      &:last-child {
        border: 0;
      }

      &.fh-103 {
        height: 103px;
      }

      &.fh-80 {
        height: 80px;
      }

      &.fh-90 {
        height: 90px;
      }

      a:not(.orange-btn) {
        color: #212529;
      }

      a:not(.orange-btn):hover {
        color: #2c78ff;
      }

      .fa-check {
        color: #2c78ff;
        margin-right: 8px;
        font-size: 15px;
      }

      .fa-times {
        color: #a0a0a0;
        margin-right: 8px;
        font-size: 17px;
      }
    }
  }

  &-label {
    background-color: #f9f9f9;

    ul {
      li {
        justify-content: flex-start;
        text-align: left;
        border-color: #e3e3e3;
      }
    }
  }

  .sub-title {
    background-color: #f8faff;
    h3 {
      margin-bottom: 0;
      font-weight: 600;
      font-size: 15px !important;
    }
  }
}

.Price-clm {
  background-color: #fcf0e5;
}

.higlighted_clm {
  background-color: #f8faff;

  .package-name {
    h3 {
      color: #fd7900;
    }
  }

  ul li a.blue-btn {
    color: #fff;

    &:hover {
      color: #fff;
    }
  }
}

h6 {
  font-weight: 600;
}

.highlight_heading_box {
  background-color: #2c78ff;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 15px;
  position: relative;
  height: 150px;
  top: -16px;

  &:before {
    position: absolute;
    content: "";
    background-color: #2c78ff;
    height: 20px;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    left: 0;
    right: 0;
    bottom: 99%;
  }

  &:after {
    position: absolute;
    content: "";
    background-color: #2c78ff;
    height: 20px;
    width: 100%;
    border-radius: 0px 0px 20px 20px;
    left: 0;
    right: 0;
    top: 99%;
  }
}

.highlight_block_mobile {
  background-color: #f8faff;
  @include mobile {
    margin-top: 50px;
  }
}
