.FAQs-hero {
  background-image: url(../../img/FAQs.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  display: block;
  border-radius: 30px;
  text-align: center;
  position: relative;

  h1.vertical-center {
    position: absolute;
    color: white;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
  }
}

.accordion-box {
  height: 1000px;
  .accordian-properties {
    margin: 10px 0px;
    padding-top: 10px;
  }
  .accordion-collapse {
    border: transparent;
    border-top: 0px;
  }
  .accordion-body {
    color: #545454;
  }
  .accordion-item {
   border-top: none;
   border-left: none;
   border-right: none;
  }
}



.accordion-item:not(:first-of-type) {
  border-top: 1px solid #cbcbcb;
}

.accordion-button:not(.collapsed) {
  color: #2c78ff;
  background-color: #f8faff;
  border-color: transparent;
  outline: 0;
}

.accordion-button:focus {
  border-color: transparent;
  outline: 0;
  border-radius: calc(0.25rem - 1px) !important;
}

.accordion-button:focus {
  box-shadow: none;
}
