html,
body {
  overflow-x: hidden;

  margin: 0;
  padding: 0;
}

body {
  position: relative;

  font-family: "Mulish", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}
