@import "./mixins.scss";

.navbar-dark .navbar-nav .nav-link {
  color: #000;
}

/* utility class start */
h1 {
  font-size: 42px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  @include mobile {
    font-size: 25px;
  }  
}

h2 {
  font-size: 32px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  @include mobile {
    font-size: 30px;  
  }
  @include mds-mobile {
    font-size: 25px;
  }
}

h3 {
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  @include mobile {
    font-size: 18px;
    line-height: 1.8rem;
  }
}

h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5em;
  font-family: "Poppins", sans-serif;
}

h5 {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.8em;
  font-family: "Poppins", sans-serif;
}

h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8em;
  font-family: "Poppins", sans-serif;
}

p {
  font-size: 16px;
  color: #545454;
  line-height: 1.8em;
  font-family: "Mulish", sans-serif;
}

a {
  // font-size: 15px;
  font-family: "Mulish", sans-serif;
}

.blue-text {
  color: #2c78ff;
}

.font-light {
  font-weight: normal;
}

.orange-text {
  color: #fd7800;
}

.padding-right {
  padding-right: 60px;
}

.padding-y-80 {
  padding: 80px 0;
}

.padding-y-40 {
  padding: 40px 0;
  @include mobile {
    padding: 20px 0px;  
}
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-y-20 {
  padding: 20px 0;
}

.bottom-margin-40 {
  margin-bottom: 40px;
}

.bottom-margin-80 {
  margin-bottom: 80px;
}

.bottom-margin-60 {
  margin-bottom: 60px;
}

.margin-top-80 {
  margin-top: 80px;
}
.top-space {
  margin-top: 40px;
}

//  button styling start
.orange-btn {
  background-image: linear-gradient(
    to right,
    #fd7800 0%,
    #fd8f00 51%,
    #fd7800 100%
  );

  padding: 10px 26px;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 50px;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.orange-btn:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
  padding: 10px 18px;
  box-shadow: 0 9px 13px #fd8b0056;
  // box-shadow: 0 9px 13px #fd8b0056;
  // transform: translateY(-5px);
  // transition: 0.3s;

  .tri-btn {
    margin-left: 6px;
    opacity: 1;
  }
}

.blue-btn {
  background-image: linear-gradient(
    to right,
    #2c78ff 0%,
    #91b9ff 51%,
    #2c78ff 100%
  );
}
.blue-btn {
  // margin-top: -22px;
  padding: 10px 26px;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 50px;
  display: flex;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  width: fit-content;

  @include tablet {
    padding: 7px 25px;
  }
}

.blue-btn:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
  padding: 10px 18px;
  box-shadow: 0 9px 13px #2c79ff52;
  .tri-btn {
    margin-left: 6px;
    opacity: 1;
  }
}
//  button styling end

.right-img {
  img {
    // display: inline-block;
    width: 90%;
    margin: 0 auto;
    float: right;
    @include mobile {
      float: none;
      width: 100%;
    }
  }
}

.left-img {
  img {
    display: inline-block;
    width: 90%;
    margin: 0 auto;
    @include mobile {
      width: 100%;
    }
  }
}

/*  utility class end */
.margin-top-80 {
  @include mobile {
    margin-top: 30px;
  }
}

.padding-y-40 {
  @include mobile {
    padding: 25px 0px;
  }
}

.padding-y-80 {
  @include mobile {
    padding: 25px 0px;
  }
}

.paragraph-wrpr-row {
  @include mobile {
    text-align: center;
  }

  .left-img {
    @include mobile {
      margin-top: 15px;
      margin-bottom: 10px;
      display: inline-flex;
    }
  }

  .right-img {
    @include mobile {
      margin-top: 10px;
      margin-bottom: 10px;
      display: inline-flex;
    }
  }

  .li-wrapper {
    @include mobile {
      text-align: center;
    }
  }

  .padding-right {
    @include mobile {
      padding-right: 0px;
    }
  }
}

a.btn-on-img {
  @include mobile {
    display: inline-flex;
  }
}

.light-bg {
  @include mobile {
    height: 100%;
    width: 100%;
    padding: 20px 42px;

  @include mds-mobile {
    padding: 20px 0px;
  }
    .container {
      @include mobile {
        position: relative;
        top: 0;
        transform: none;
      }

      .anim-card {
        width: 100%;
      }
    }
  }
}

.abt-feature-cardone {
  @include mobile {
    margin-bottom: 30px;
  }
}

.margin-y {
  @include mobile {
    margin: 0px;
  }
}

.abt-demo-phone {
  @include mobile {
    padding: 40px 0 40px 0;
  }
}

.inner-hero-phone {
  h1.vertical-center {
    @include mobile {
      top: 60%;
      transform: translate(-50%, -54%);
    }
  }
}

.center-heading h5 {
  @include mobile {
    padding: 5px 20px 10px 20px;
  }
}

.features_li_phn_wrapper {
  .right-img {
    @include mobile {
      margin-top: 10px;
      margin-bottom: 25px;
      display: inline-flex;
    }
  }

  .left-img {
    @include mobile {
      margin-top: 10px;
      margin-bottom: 25px;
      display: inline-flex;
    }
  }
}

.fe-main-category {
  @include mobile {
    padding: 0px 20px;
  }

  .feature-card {
    @include mobile {
      width: 100%;
    }
  }

  .padding-y-40 {
    @include mobile {
      padding: 25px 0px;
    }
  }
}

.feature-cat-wrapper {
  @include mobile {
    margin-top: 6px;
  }
}

.demo_card_center_phone {
  @include mobile {
    text-align: center;
  }
}

.demo-li {
  @include mobile {
    padding: 34px 30px;
    width: 100%;
  }
}

.demo_col_phone:first-child {
  @include mobile {
    margin-bottom: 24px;
  }
}

.demo-detail-section {
  @include mobile {
    padding: 40px 0px 20px 0px;
  }

  .column-bg {
    @include mobile {
      width: 90%;
      margin: 0 auto;
    }
    @include x-mobile{
      margin-top: 20px;
    }
    @include mds-mobile{
      margin-top: 20px;
    }
  }

  .demo-img-wrapper {
    @include mobile {
      text-align: center;
    }
  }

  .demo-content-wrapper {
    @include mobile {
      text-align: center;
      padding: 25px;
    }
  }

  .blue-cta {
    display: inline-block;
  }
}

.bottom-margin-80 {
  @include mobile {
    margin-bottom: 30px;
  }
}

.eop-cta-wrapper {
  .cta-text-wrapper {
    @include mobile {
      text-align: center;
    }
    p {
      @include mobile {
        padding-right: 0px !important;
      }
    }
  }

  .cta-wrapper {
    @include mobile {
      text-align: center;
      padding: 0px 10px;
    }
  }
}

.contact-card {
  .qoute-left:before {
    @include mobile {
      width: 24px;
      height: 24px;
      left: 15px;
      top: 27px;
    }
  }

  .qoute-right:after {
    @include mobile {
      width: 24px;
      height: 24px;
      right: 15px;
      bottom: 96px;
    }
  }

  .feature-content-wrapper {
    @include mobile {
      padding: 10px 22px;
    }
  }
}

.form-title {
  @include mobile {
    text-align: center;
  }
}

.form_col {
  @include mobile {
    margin-bottom: 40px;
  }
  .form_alignment_phone {
    @include mobile {
      text-align: center;
    }
  }
}

.contact-cta-wrapper {
  @include mobile {
    width: 90%;
  }

  .cta-text-box {
    @include tablet {
      text-align: center;
    }
  }
}

.footer-section {
  @include mobile {
    padding: 35px 18px;
  }
}

.language_selector {
  @include mobile {
    display: none;
  }
}

.secondary-menu-row p {
  @include mobile {
    text-align: center;
  }
}

.navbar-collapse {
  @include tablet {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    background-color: #fffffffa;
    border-radius: 14px;
    padding: 15px;
    box-shadow: 0px 0px 43px #99999991;
    margin-top: 7px;

    .navbar-nav a.nav-link:not(.menu-btn) {
      color: #5a5a5a !important;
      font-family: "Mulish", sans-serif;
      font-weight: 600;
      line-height: 1.8rem;

      &:hover::after {
        display: none;
      }

      &:hover {
        color: #fd7800;
      }
    }
  }
}

h1 {
  @include tablet {
    font-size: 35px;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    letter-spacing: 1px;
  }
}

.phone-center {
  @include mobile {
    margin: 0 auto;
  }
}

.no-phone-pb {
  @include mobile {
    padding-bottom: 0;
  }
}

.no-phn-pt {
  @include mobile {
    padding-top: 0;
  }
} 

.bottom-space-40 {
  margin-bottom: 40px;
}

.mb-phone-20 {
  @include md-mobile {
    margin-bottom: 20px;
  }
}

.no-padding-phone {
  @include md-mobile {
    padding-top: 0px;
  }
}

.captcha-code-wrap {
  border: none;
}

.anchore-tag-prop {
  background-color: transparent;
  border: none;
  color: #2c78ff;
  text-decoration: underline;
}

.anchore-tag-prop:focus-visible {
  outline: none;
}