@import "../../styles/mixins";
.about-hero {
  background-image: url(../../img/AboutHero.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  display: block;
  border-radius: 30px;
  text-align: center;
  position: relative;

  h1.vertical-center {
    position: absolute;
    color: white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.margin-y {
  margin: 90px 0px;
}

.light-bg {
  background-color: #faf9ff;
  height: 200px;
  width: 100%;
  padding-bottom: 80px;
  position: relative;

  .container {
    position: relative;
    top: 50%;
    transform: translate(0px, -35%);
  }

  .anim-card-row {
    .anim-card {
      background-color: #fff;
      width: 90%;
      text-align: center;
      border-radius: 22px;
      padding: 50px 25px;
      box-shadow: 0 0px 13px #9999993b;

      .digit-caption {
        text-align: center;
        position: inherit;
        display: inline-block;

        img {
          height: 60px;
          width: 60px;
          margin-bottom: 10px;
        }

        .digits {
          font-size: 30px;
          font-weight: 600;
          color: #2c78ff;

          &::after {
            position: absolute;
            content: "\002B";
            color: #2c78ff;
            text-align: right;
          }
        }

        .caption {
          color: #717171;
          font-size: 18px;
          font-family: "Mulish", sans-serif;
          font-weight: 600;
        }
      }
    }
  }
}

.center-heading {
  display: block;
  text-align: center;
}



.top-space {
  margin-top: 40px;
}

.feature-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  background: rgb(255, 255, 255);
  border-radius: 22px;
  box-shadow: 0 0px 13px #9999993b;
  transition: 0.2s ease-out;
  justify-content: space-between;

  .feature-content {
    padding: 40px 60px 40px 60px;
    @include md-mobile {
      padding: 20px 20px;
    }
  }
  &:hover {
    background: linear-gradient(to right, #2c78ff 0%, #91b9ff 70%);
    color: #fff;
    background-size: 500% auto;

    &:hover a.feature-btn {
      background-image: linear-gradient(to right, #fff 0%, #fff 100%);
      transition: 0.3s ease-out;
    }

    &:hover a.feature-btn svg {
      color: #76a7ff;
    }

    &:hover .image-wrapper02 {
      background-image: url(../../img/AdminSupport.png-hover.png);
    }

    &:hover .image-wrapper01 {
      background-image: url(../../img/user-support-hover.png);
    }

    p {
      color: #fff;
      transition: 0.3s ease-out;
    }
   
  }

  .feature-content {
    .image-wrapper01 {
      background-image: url(../../img/user-support-icon.png);
      width: 70px;
      height: 70px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-bottom: 20px;
    }

    .image-wrapper02 {
      background-image: url(../../img/AdminSupport.png);
      width: 70px;
      height: 70px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      margin-bottom: 20px;
    }
  }

  .feature-btn-wapper {
    text-align: right;

    a.feature-btn {
      background-image: linear-gradient(
        to right,
        #2c78ff 0%,
        #91b9ff 51%,
        #2c78ff 100%
      );
      transition: 0.3s ease-out;
      padding: 10px 50px;
      text-align: center;
      text-transform: capitalize;
      transition: 0.5s ease-out;
      background-size: 200% auto;
      color: white;
      border-radius: 22px 0px 22px 0px;
      display: inline-block;
    }

    a.feature-btn svg {
      font-size: 40px;
      color: white;
      transition: 0.3s ease-out;
    }
  }
}

.li-wrapper {
  background-color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
  border-radius: 22px;
  padding: 40px 35px;
  box-shadow: 0 0px 13px #9999993b;

  h4 {
    color: #2c78ff;
  }

  p {
    margin-bottom: 0px;
  }
}


