@import "../../styles/mixins";

.sale-question-hero {
    background-image: url('../../img/sale-question-hero.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    display: block;
    border-radius: 30px;
    text-align: center;
    position: relative;
  
    h1.vertical-center {
      position: absolute;
      color: white;
      left: 50%;
      top: 55%;
      transform: translate(-50%, -50%);
    }
  }
  
  .accordion-box {
    height: 1050px;
    .accordian-properties {
      margin: 10px 0px;
      padding-top: 10px;
    }
    .accordion-collapse {
      border: transparent;
      border-top: 0px;
    }
    
    .accordion-body {
      color: #545454;
    }
    .accordion-item {
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
  
  .accordion-button:not(.collapsed) {
    color: #2c78ff;
    background-color: #f8faff;
    border-color: transparent;
    outline: 0;
  }
  
  .accordion-button:focus {
    border-color: transparent;
    outline: 0;
    border-radius: calc(0.25rem - 1px) !important;
  }
  
  .accordion-button:focus {
    box-shadow: none;
  }

  .margin-bottom-50{
    margin-bottom: 50px;
  }
  .padding-y-70{
    padding: 70px 0px;
  }

  .sale-content{
    @include mobile {
      margin-top: 300px;
    }
    @include xsm-mobile {
      margin-top: 460px;
    }
  }

  .padding-phone-30 {
    @include md-mobile {
      padding-top: 30px;
    }
  }

  .margin-bottom-110 {
    margin-bottom: 110px;
  }