@import "../../styles/mixins";
.blog-hero {
    background-image: url(../../img/bloghero.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    display: block;
    border-radius: 30px;
    text-align: center;
    position: relative;
  
    h1.vertical-center {
      position: absolute;
      color: white;
      left: 50%;
      top: 55%;
      transform: translate(-50%, -50%);
    }
  }

 
  .blog-clm {
    position: relative;
    background-color: #fff;
    width: calc(100% - 30px);
    text-align: center;
    border-radius: 40px;
    padding: 40px 25px;
    box-shadow: 0 9px 13px #9999993b;
    margin: 15px;
    height: 100%; 
   
    .blog-body {
      margin-top: 10px;
  
      p {
        margin-bottom: 3rem;
      }
    }
  
    .blog-img {
      margin-bottom: 18px;
  
      img {
        width: 100%;
        border-radius: 15px;
        display: block;
      }
    }
  
    .blog-body {
      text-align: left;
    }
  
    .blog-btn {
      background-image: linear-gradient(
        to right,
        #fd7800 0%,
        #fd8f00 51%,
        #fd7800 100%
      );
      margin: 10px 0 0 0;
      padding: 10px 18px;
      text-transform: capitalize;
      transition: 0.5s;
      background-size: 200% auto;
      color: white;
      border-radius: 50px;
      display: inline-block;
      text-decoration: none;
    }
  
    .blog-btn:hover {
      background-position: right center; /* change the direction of the change here */
      color: #fff;
      text-decoration: none;
      box-shadow: 0 9px 13px #fd8b0056;
      transform: translateY(-5px);
      transition: 0.3s;
    }
  }
  
  .blog-btn-div {
    text-align: left;
    margin-top: -33px;
  }
  
  .blog-title {
    display: block;
    text-align: center;
  }

  .blog-wrapper{
    @include mobile{
      margin-bottom: 40px;
    }
  }