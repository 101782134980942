.terms-Condition-hero {
    background-image: url('../../img/termsandcondition1.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    display: block;
    border-radius: 30px;
    text-align: center;
    position: relative;
  }

  h1.vertical-center {
    position: absolute;
    color: white;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
  }
  
  .vertical-center span{
    font-size: 30px;
    display: block;
    padding: 12px 0px;
  }
  .li-wrapper {
      background-color: #fff;
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      text-align: left;
      border-radius: 22px;
      padding: 40px 35px;
      box-shadow: 0 0px 13px #9999993b;
    
      .terms-subtitle{
        font-size: 20px;
        font-weight: 600;
        color: black;
      }
  
      p {
        margin-bottom: 0px;
      }
  
      strong{
        margin-top: 5px;
        color: #545454;;
      }
      a{
        text-decoration: none;
        color: black;
      }
    }
  
    .terms-margin-30 {
      margin-top: 30px;
    }
  
    .side-pattern{
      position: relative;
    }
    .side-pattern::after{
      background-image: url('../../img/dottedpattern.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      content: "";
      height: 95px;
      width: 173px;
      top: 33px;
      right: -24px;
      z-index: -1;
    }
    .side-pattern::before{
      background-image: url('../../img/dottedpattern.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: absolute;
      content: "";
      height: 95px;
      width: 173px;
      bottom: 33px;
      left: -24px;
      z-index: -1;
    }
  
    .bulletlisting ul {
      display: inline-block;
      padding: 0px 27px;
      list-style-type: none;
      color: #545454;
    }
    .bulletlisting ul li{
      line-height: 30px;
    }
    .bulletlisting ul li::marker {
      content: "\2794\0020";
      color: #2c78ff;
      font-size: 22px;
      margin-left: 10px;
    }
  
      
    