@import "../../styles/mixins";

.contact-hero {
  background-image: url(../../img/ContactHero.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  display: block;
  border-radius: 30px;
  text-align: center;
  position: relative;

  h1.vertical-center {
    position: absolute;
    color: white;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
  }
}

.contact-card {
  background-color: #fff;
  text-align: left;
  border-radius: 22px;

  // box-shadow: 0 0px 13px #9999993b;

  .contact-content-wrapper {
    padding: 20px 10px;
  }
}

// .qoute-left {
//   position: relative;

//   &:before {
//     position: absolute;
//     content: "";
//     background-image: url(../../img/qoute.png);
//     width: 40px;
//     height: 40px;
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     left: 0;
//     left: 56px;
//     top: 44px;
//   }
// }

// .qoute-right {
//   position: relative;

//   &:after {
//     position: absolute;
//     content: "";
//     background-image: url(../../img/qoute.png);
//     width: 50px;
//     height: 50px;
//     background-position: center;
//     background-repeat: no-repeat;
//     background-size: cover;
//     right: 56px;
//     bottom: 44px;
//   }
// }

.text-contact-wrapper {
  text-align: left;

  img {
    height: 74px;
    width: 74px;
  }

  p {
    margin-top: 10px;
    font-weight: 600;
  }
}

.contact-btn-wrapper {
  @include mobile {
    padding: 0px 0px !important;
  }
}

.horizontal-center {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  @include mobile {
    display: block;
  }
}

.contact-cta-wrapper {
  background-color: #1f1f1f;
  vertical-align: middle;
  padding: 15px 0;
  border-radius: 22px;

  .cta-text-box {
    padding-right: 24px;
    @include mobile {
      padding-right: 0px;
    }
    h5 {
      color: #fff;
    }
  }
}

button.form-btn {
  background-image: linear-gradient(
    to right,
    #fd7800 0%,
    #fd8f00 51%,
    #fd7800 100%
  );
  margin: 10px 0 0 0;
  padding: 10px 45px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  width: max-content;
  border: none;

  @include mobile {
    margin: 0 auto;
  }
}

button.form-btn:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
  box-shadow: 0 9px 13px #fd8b0056;
  transition: 0.3s;
  padding: 10px 37px;
  .tri-btn {
    margin-left: 6px;
    opacity: 1;
  }
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #fd8b00;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #fd8b003d;
}

.form-control {
  padding: 1.1rem 0.75rem;
  border-radius: 0.7rem;
}

.form-title {
  margin-bottom: 20px;
}

.form-li {
  background-color: #fff;
  text-align: center;
  border-radius: 22px;
  padding: 40px 35px;
  box-shadow: 0 0px 13px #9999993b;

  h3 {
    text-align: left;
    margin-bottom: 25px;
  }

  .get-in-touch {
    display: flex;
    text-align: left;

    p {
      font-size: 16px;
      color: #545454;
      line-height: 1.8em;
      margin-left: 10px;
      margin-top: -7px;
    }

    svg {
      color: #fbcca2;
      font-size: 26px;
    }
  }

  .social-media {
    text-align: left;
    margin-top: 26px;

    a.social-icon {
      padding: 15px 10px;
      color: #777777;
      transition: 0.5s ease-in-out;
      display: inline-block;
      width: 48px;
      height: 48px;
      box-shadow: none;
      border-radius: 50px;
      text-align: center;
      line-height: 0px;

      &:hover {
        color: #2c78ff;
        transition: 0.5s ease-in-out;
        box-shadow: 0 9px 13px #2c79ff3d;
        border-radius: 50px;
        background-color: #fff;
      }
    }
  }
}

.dropdown-selector .form-select {
  padding: 1.1rem 0.75rem;
  border-radius: 0.7rem;
}

.dropdown-selector .form-select:focus {
  color: #6c757d;
  background-color: #fff;
  border-color: #fd8b00;
  outline: 0;
  box-shadow: 0 0 0 0.25rem #fd8b003d;
}

.align-right {
  margin-left: auto;
  @include mobile {
    margin-right: auto;
    margin-top: 10px;
  }
}

.captcha-code-wrap {
  border: none;
  width: auto;
  margin: 0px 10px 0px 0px;
  padding: 18px 0;
  box-shadow: 0px 0px 10px rgba(151, 151, 151, 0.44);
  border-radius: 10px;
  height: 60px;
}

.flex-prop {
  display: flex;
  align-items: flex-start;
}