.fb-blog-hero {
  background-image: url('../../../img/fbblogevent.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  display: block;
  border-radius: 30px;
  text-align: center;
  position: relative;
}
.startup-blog-hero {
  background-image: url('../../../img/startup-blog-hero.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  display: block;
  border-radius: 30px;
  text-align: center;
  position: relative;
}
.major-role-blog-hero {
  background-image: url('../../../img/major-role-blog-hero.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  display: block;
  border-radius: 30px;
  text-align: center;
  position: relative;
}
h1.vertical-center {
  position: absolute;
  color: white;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
}

.vertical-center span{
  font-size: 30px;
  display: block;
  padding: 12px 0px;
}
.li-wrapper {
    background-color: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: left;
    border-radius: 22px;
    padding: 40px 35px;
    box-shadow: 0 0px 13px #9999993b;
  
    .blog-subtitle{
      font-size: 20px;
      font-weight: 600;
      color: black;
    }

    p {
      margin-bottom: 0px;
    }

    strong{
      margin-top: 5px;
    }
    a{
      text-decoration: none;
      color: black;
    }
  }

  .blogtop-margin-30 {
    margin-top: 30px;
  }

  .side-pattern{
    position: relative;
  }
  .side-pattern::after{
    background-image: url('../../../img/dottedpattern.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    content: "";
    height: 95px;
    width: 173px;
    top: 33px;
    right: -24px;
    z-index: -1;
  }
  .side-pattern::before{
    background-image: url('../../../img/dottedpattern.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    content: "";
    height: 95px;
    width: 173px;
    bottom: 33px;
    left: -24px;
    z-index: -1;
  }

  