$tablet-width: 992px;
$mobile-width: 768px;
$desktop-width: 1024px;
$md-mobile-width: 576px;
$mds-mobile-width: 426px;
$iphonexs-mobile-width: 395px;
$x-mobile-width: 376px;
$xx-mobile-width: 361px;
$xsm-mobile-width: 281px;

@mixin desktop {
  @media (max-width: #{$desktop-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin md-mobile {
  @media (max-width: #{$md-mobile-width - 1px}) {
    @content;
  }
}

@mixin mds-mobile {
  @media (max-width: #{$mds-mobile-width - 1px}) {
    @content;
  }
}
@mixin iphonexs-mobile {
  @media (max-width: #{$iphonexs-mobile-width - 1px}) {
    @content;
  }
}
@mixin x-mobile {
  @media (max-width: #{$x-mobile-width - 1px}) {
    @content;
  }
}
@mixin xx-mobile {
  @media (max-width: #{$xx-mobile-width - 1px}) {
    @content;
  }
}

@mixin xsm-mobile {
  @media (max-width: #{$xsm-mobile-width - 1px}) {
    @content;
  }
}
