@import "../../styles/mixins";

.secondary-menu {
  &-row {
    padding: 0.7rem 0.9rem 0.7rem 0.9rem;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin-bottom: 0px;
      @include x-mobile{
        font-size: 13px;
      }
      @include mds-mobile{
        font-size: 14px;
      }
      @include tablet{
        font-size: 14px;
      }
    }
  }
.phone-number a{
  text-decoration: none;
  font-weight: bold;
  color: #545454;
}
  .language_selector {
    .form-select {
      border-radius: 2.25rem;
      font-family: "Poppins", sans-serif;
      padding: 0.6rem 2.25rem 0.6rem 0.75rem;
      font-size: 15px;
      color: #5a5a5a;
      border: 1px solid #707070;

      &:focus {
        border-color: #fd8b00;
        box-shadow: 0 0 0 0.25rem #fd8b003d;
      }

      @include tablet{
        padding: 0.4rem 2.25rem 0.4rem 0.75rem;
        margin-left: -15px;
        width: auto;
        font-size: 14px;
      }

      option {
        margin-top: 10px;
        font-weight: normal;
        display: block;
        white-space: nowrap;
        min-height: 1.2em;
        padding: 0px 2px 1px;
        border-radius: 30px !important;
      }
    }
  }
}

.search {
  float: right;
  text-align: right;
}

.navbar-brand img {
  width: 220px;
  @include x-mobile {
    width: 200px;
  }
  @include xsm-mobile {
    width: 160px;
  }
}

.navbar {
  position: absolute !important;
  z-index: 5;
  display: flex;
  width: 100%;
}

.navbar > .container {
  padding: 0 32px;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff !important;
}

.navbar-dark .navbar-nav .nav-link:not(.menu-btn) {
  padding: 0 16px;
}

button.menu-btn {
  border: none;
}

.navbar-dark .navbar-nav .nav-link.menu-btn {
  color: #5a5a5a !important;
  padding: 0 16px;
  font-weight: 600;
}
.menu-tri-btn svg {
  display: none;
}

@media (min-width: 991px) {
  .navbar-dark .navbar-nav .nav-link.menu-btn {
    background-image: linear-gradient(
      to right,
      #fd7800 0%,
      #fd8f00 51%,
      #fd7800 100%
    );
    margin: 10px 0px 10px 0px;
    padding: 9px 3px;
    text-align: center;
    text-transform: capitalize;
    transition: 0.5s;
    background-size: 200% auto;
    color: white !important;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    float: right;
    width: 141px;
  }
}

.menu-btn:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
  padding: 10px 18px;
  .tri-btn {
    margin-left: 6px;
    opacity: 1;
  }
}

.navbar-nav a {
  position: relative;
  font-family: "Mulish", sans-serif;

  &:not(.menu-btn):hover::after {
    position: absolute;
    content: "";
    height: 5px;
    width: 5px;
    border-radius: 30px;
    background-color: #fd7800;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: -14px;
  }

  &:not(.menu-btn):active::after {
    position: absolute;
    content: "";
    height: 5px;
    width: 5px;
    border-radius: 30px;
    background-color: #fd7800;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: -14px;
  }
}

.navbar-dark .navbar-toggler {
  border: 2px solid #747474;
  opacity: 0.9;
}

.toggler-icon{
  @include tablet{
    padding: 2px 8px;
    font-size: 18px;
  }
  @include x-mobile {
    padding: 2px 8px;
    font-size: 18px;
  }
  @include xsm-mobile {
    padding: 2px 4px;
    font-size: 16px;
  }
}
