  @import "../../styles/mixins";

.banner {
  position: relative;
  background-image: url(../../img/banner-img.svg);
  height: 464px;
  width: 100%;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  left: 0;
  right: 0;
  padding: 120px 0 0px 50px;
}

.home-carosel {
  position: relative;

  .carousel {
    border-radius: 30px;
    overflow: hidden;

    .slide-img {
      @include mobile {
        height: 400px;
        object-fit: cover;
      }
      @include xsm-mobile {
        height: 360px;
        object-fit: cover;
      }
    }
  }

  .carousel-indicators {
    display: none;
  }

  .slider-caption {
    right: 0;
    left: 15%;
    text-align: left;
    top: 8.25rem;
    max-width: 620px;

    @include tablet {
      left: 50%;
      text-align: center;
      top: 45%;
      transform: translate(-50%, -45%);
    }
    @include md-mobile {
      left: 50%;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    

    p {
      margin-bottom: 2rem;
      color: #fff;
      @include tablet {
        margin-bottom: 1.5rem;
        line-height: 22px;
      }

      @include mds-mobile{
        line-height: 21px;
      }
    }

    h3 {
      margin-bottom: 0.8rem;
    }

    .blue-btn {
      @include tablet {
        margin: 0 auto;
      }
      @include xsm-mobile{
        padding: 7px 15px;
        font-size: 14px;
      }
    }

    h1{
      @include mds-mobile{
        font-size: 30px;
      }
      @include xsm-mobile{
        font-size: 22px;
      }
    }
  }

  .anim-number-wrapper {
    background-color: #fff;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 322px;
    height: 142px;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      background-image: url("../../img/banner-shape1.png");
      height: 35px;
      width: 35px;
      top: -30px;
      right: -4px;
    }

    &::after {
      content: "";
      position: absolute;
      background-image: url("../../img/banner-shape2.png");
      height: 142px;
      width: 133px;
      left: -92px;
      top: 0;
    }

    .anim-number-boxs {
      display: flex;
      position: relative;
      flex-wrap: wrap;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 6px;
        background-color: #c6c6c6;
        border-radius: 10px;
        left: 0;
        bottom: -30px;
        z-index: 1;
      }
    }
    .number-box {
      margin-top: 40px;
      position: relative;
      padding: 0 5px;
      text-align: center;
      flex: 0 0 50%;
      max-width: 50%;
    }

    .number-line {
      background-color: #2c78ff;
      width: 50%;
      height: 6px;
      position: absolute;
      bottom: -30px;
      z-index: 2;
      left: 0;
      border-radius: 10px;
      transition: 0.5s;
    }
    .number-box:nth-child(2):hover ~ .number-line {
      left: 50%;
    }
    .anim-number-wrapper:first-child {
      margin-right: 30px;
      z-index: 1;
    }

    .number-box {
      .digits {
        font-size: 30px;
        font-weight: 600;
      }

      .caption {
        font-size: 15px;
        color: #a2a2a2;
      }
    }

    .round-button {
      padding-top: 45px;
      margin-left: 45px;

      img {
        width: 70%;
        vertical-align: middle;
      }
    }
  }
}

.tri-btn {
  margin-left: -10px;
  opacity: 0;
  transition: 0.5s;
  line-height: 1;

  svg {
    height: 14px;
    width: 14px !important;
    color: white;
    position: relative;
  }   
}

.text-section h1 {
  color: #fff;
  margin-bottom: 30px;
}

.text-section p {
  color: #e2e2e2;
  margin-bottom: 30px;
}

.anim-col {
  height: 100%;
  display: flex;
}

.anim-number {
  align-self: flex-end;
}

.first-clm {
  position: relative;
  background-color: #fff;
  height: max-content;
  width: 100%;
  text-align: center;
  border-radius: 40px;
  padding: 40px 25px;
  box-shadow: 0 9px 13px #9999993b;
  height: 100%;
  align-items: stretch;
  @include mobile {
    height: auto;
  }

  &:after {
    content: "";
    position: absolute;
    height: 61px;
    width: 179px;
    background-color: #fff;
    box-shadow: 0 9px 13px #9999993b;
    bottom: -30px;
    left: 50%;
    border-radius: 39px;
    transform: translateX(-50%);
  }

  .clm-img {
    position: relative;
    width: 62px;
    display: inline-block;

    img {
      position: relative;
      width: 100%;
      z-index: 1;
    }

    &::after {
      content: "";
      height: 50px;
      width: 50px;
      position: absolute;
      background-color: #e3edff;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      border-radius: 100px;
      transition: 0.5s ease-out;
    }
  }

  &:hover .clm-img::after {
    content: "";
    height: 70px;
    width: 70px;
    position: absolute;
    background-color: #e3edff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 100px;
    transition: 0.5s ease-out;
  }

  .clm-body {
    margin-top: 10px;
    text-align: center;

    & h3{
      @include tablet{
        font-size: 17px;
      }
      @include mobile{
        font-size: 17px;
      }
    }
    & p{
      @include tablet{
        font-size: 16px;
      }
      @include mobile{
        font-size: 16px;
      }
    }
  }
}

.clm-btn-div {
  text-align: center;
  margin-top: -33px;
  width: 163px;
  margin: 0 auto;
  position: relative;
  .clm-btn {
    margin-top: -22px;
  }
}

.font-light {
  font-weight: normal;
}

a.btn-on-img {
  background-image: linear-gradient(
    to right,
    #2169e9 0%,
    #2c78ff 51%,
    #2169e9 100%
  );
}
a.btn-on-img {
  margin: -28px 0 0 0;
  padding: 10px 39px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 9px 13px #216ae969;
  border-radius: 9px;
  display: flex;
  text-decoration: none;
  width: fit-content;
  position: relative;
  bottom: 27px;

  @include tablet{
    font-size: 14px;
    padding: 8px 34px;
  }
  @include mobile{
    padding: 8px 20px;
  }
  @include xsm-mobile{
    font-size: 14px;
    padding: 8px 20px;
  }
}

a.btn-on-img:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

a.btn-on-img {
  color: white;
  text-decoration: none;
  display: flex;
  line-height: 35px;
}

.btn-on-img a:hover {
  color: white;
  text-decoration: none;
  display: flex;
  line-height: 35px;
}

.demo-btn {
  background-color: white;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  text-align: center;
  margin-left: 22px;

  @include mobile{
    height: 35px;
    width: 35px;
  }
  @include xsm-mobile{
    height: 30px;
    width: 30px;
  }
}

.testimonial-wrapper {
  color: white;
  height: 300px;
  width: 762px;
  box-shadow: 0 2px 22px rgba(155, 155, 155, 0.322);
  border-radius: 21px;
  text-align: center;
}

.testimonial-slider {
  padding: 72px 60px;
}

.testimo-graphic {
  position: relative;
  height: 100px;

  &::after {
    content: "";
    position: absolute;
    height: 373px;
    width: 650px;
    background-color: #fff3e8;
    border-radius: 20px;
    top: 44%;
    left: 20%;

    @include tablet{
      height: 245px;
      width: 660px;
      top: 58%;
      left: 12%;
    }
    @include md-mobile{
      height: 298px;
      width: 465px;  
      left: 10%;
    }
    @include mds-mobile{
      width: 350px;
      height: 365px;
      top: 63%;
      left: 10%;
    }
    @include iphonexs-mobile{
      width: 330px;
      height: 375px;
      top: 68%;
      left: 10%;
    }
    @include x-mobile{
      width: 310px;
      height: 390px;
      top: 63%;
      left: 12%;
    }
    @include xx-mobile{
      width: 310px;
      height: 370px;
      top: 80%;
      left: 9%;
    }
    @include xsm-mobile{
      width: 236px;
      height: 480px;
      top: 80%;
      left: 9%;
    }
    
  }
  @include desktop{
    margin-top: -70px;
  }
  @include md-mobile{
    margin-top: -30px;
  }
  }


.testimo-bg {
  height: 350px;
  width: 750px;
  border-radius: 20px;
  box-shadow: 0 2px 22px rgba(155, 155, 155, 0.322);
  border-radius: 21px;
  background-color: white;
  position: relative;
  z-index: 1;

  &::before {
    position: absolute;
    content: "";
    background-image: url(../../img/qoute.png);
    background-repeat: no-repeat;
    background-size: contain;
    height: 28px;
    width: 100px;
    left: 10%;
    top: 12%;

    @include mds-mobile{
      top: 6%;
    }
  }

  @include tablet{
    height: 220px;
    width: 675px;
  }
  @include md-mobile{
    height: 285px;
    width: 490px;
  }
  @include mds-mobile{
    height: 365px;
    width: 356px;
  }
  @include iphonexs-mobile{
    height: 375px;
    width: 340px;
  }
  @include x-mobile{
    height: 380px;
    width: 320px;
  }
  @include xsm-mobile{
    height: 490px;
    width: 245px;
  }

 

  &::after {
    content: "";
    background-image: url(../../img/dotted-grid-design.svg);
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    height: 20px;
    width: 100px;
    right: -11%;
    top: 12%;

    @include mds-mobile{
      top: 6%;
    }
  }

  .testimo-text {
    text-align: center;
    position: absolute;
    top: 36%;
    padding: 0 68px;

    @include tablet{
      margin-top: -20px;
    }
    @include md-mobile{
      margin-top: -35px;
      padding: 0px 15px;
    }
    @include mds-mobile{
      top: 20%;
      margin-top: -20px;
      padding: 4px 40px;
    }
    @include x-mobile{
      margin-top: -20px;
      padding: 10px 35px;
    }
    @include xsm-mobile{
      margin-top: -40px;
      padding: 12px 32px;
    }   
  }
}

.colored-bg {
  background-color: #f8f7ff;
}

.triangle-btn svg {
  color: #2c78ff;
  width: 14px !important;
  height: 49px;
  position: absolute;
  right: 52px;
  top: 5px;

  @include tablet{
    right: 47px;
    top: 4px;
  }
  @include mobile{
    right: 30px;
    top: 1px;
  }
  @include xsm-mobile{
    right: 28px;
    top: 1px;
    width: 12px !important;
  }
}

.thumbnails-wapper {
  display: flex;
  align-items: center;
  position: relative;
  left: 50%;
  margin-left: -47px;
  margin-top: -20px;
  z-index: 1;

  .avatar {
    margin-right: 20px;
    position: relative;

    .avatar-img-wrapper {
      display: inline-block;
      position: relative;
    }
    .avatar-img {
      width: 75px;
      height: 75px;
      object-fit: cover;
      border-radius: 50%;
      position: relative;
      filter: grayscale(100%);
      transition: 0.5s;

      &:hover {
        transform: scale(1.1);
        filter: grayscale(0);
      }
    }
    @include mobile{
      &:last-child{
        display: none;
      }
    }
      @include md-mobile{
        &:not(.active) {
          display: none;
        }
        }
  

    .avtar-caption {
      display: none;

      p {
        line-height: 0.8em;
      }
    }
    &.active {
      margin-right: 30px;
      .avatar-img-wrapper::before {
        content: "";
        position: absolute;
        width: 95px;
        height: 95px;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        background-color: #f1f0f8;
        border-radius: 50%;
      }
      .avatar-img {
        filter: grayscale(0%);
        transform: scale(1);
      }
      .avtar-caption {
        display: block;
        position: absolute;
        top: 100%;
        margin-top: 17px;
        width: max-content;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  button.testi-btn {
    background-image: linear-gradient(
      to right,
      #2c78ff 0%,
      #91b9ff 51%,
      #2c78ff 100%
    );
  }

  button.testi-btn {
    margin: -44px 0 0 0;
    padding: 10px 10px;
    text-align: center;
    text-transform: capitalize;
    transition: 0.5s;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 9px 13px #216ae969;
    border-radius: 50px;
    display: flex;
    text-decoration: none;
    width: fit-content;
    position: relative;
    bottom: -23px;
    height: 55px;
    width: 55px;
    border: none;

    @include mds-mobile{
      height: 40px;
      width: 40px;
    }
  }

  button.testi-btn:hover {
    background-position: right center; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
  }

  button.testi-btn svg {
    height: 36px;
    width: 28px;
    color: white;
    position: relative;
    top: 0px;
    left: 6px;
    @include mds-mobile {
      height: 29px;
      top: -5px;
      left: 4px;
    }
  }
}

.blog-container {
  margin-right: 0;
  .blog-cards-wrapper {
    position: relative;
    .blog-clm {
      position: relative;
      background-color: #fff;
      width: calc(100% - 30px);
      text-align: center;
      border-radius: 40px;
      padding: 40px 25px;
      box-shadow: 0 9px 13px #9999993b;
      margin: 15px;
      height: 470px; 
     
      .blog-body {
        margin-top: 10px;
    
        p {
          margin-bottom: 3rem;
        }
      }
    
      .blog-img {
        margin-bottom: 18px;
    
        img {
          width: 100%;
          border-radius: 15px;
          display: block;
        }
      }
    
      .blog-body {
        text-align: left;
      }
    
      .blog-btn {
        background-image: linear-gradient(
          to right,
          #fd7800 0%,
          #fd8f00 51%,
          #fd7800 100%
        );
        margin: 10px 0 0 0;
        padding: 10px 18px;
        text-transform: capitalize;
        transition: 0.5s;
        background-size: 200% auto;
        color: white;
        border-radius: 50px;
        display: inline-block;
        text-decoration: none;
      }
    
      .blog-btn:hover {
        background-position: right center; /* change the direction of the change here */
        color: #fff;
        text-decoration: none;
        box-shadow: 0 9px 13px #fd8b0056;
        transform: translateY(-5px);
        transition: 0.3s;
      }
    }
  }
 
}


.blog-btn-div {
  text-align: left;
  margin-top: -33px;
}

.blog-title {
  display: block;
  text-align: center;
}

.next-btn,
.previous-btn {
  background-image: linear-gradient(
    to right,
    #2c78ff 0%,
    #91b9ff 51%,
    #2c78ff 100%
  );
  padding: 10px 10px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 9px 13px #216ae969;
  border-radius: 50px;
  display: flex;
  height: 55px;
  width: 55px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;

  @include md-mobile{
    background-color: transparent;
    background-image: none;
    box-shadow: none;
  }
}

.previous-btn {
  right: 0px;
  @include md-mobile{
    right: -21px;
  }
}

.next-btn {
  left: -60px;
  right: auto;

  @include md-mobile{
    left: -20px;
    right: auto;
  }

}

.next-btn:hover,
.previous-btn:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

.next-btn svg,
.previous-btn svg {
  height: 36px;
  width: 26px !important;
  color: white;
  margin-left: 2px;
  @include md-mobile {
    color: #2169e9;
  }
}

.previous-btn svg {
  margin-left: 6px;
}

.anim_banner_box {
  display: block;
  @include tablet {
    display: none;
  }
}

.blog-top-space {
  padding-top: 50px;
  padding-bottom: 110px;
    @include mobile {
      margin-top: 0;
      padding-top: 120px;
    }
}

.thumbnails-wapper {
  button.testi-btn-pre.testi-btn {
    svg {
      left: 0px;
      right: 0px;
      @include mds-mobile {
        left: -2px;
      }
    }
    margin-right: 20px;
    @include mds-mobile {
      margin-right: 10px;
    }
  }
}

.testi-btn[disabled] {
 opacity: 0.5;
}

.padding-y-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}