.policy-hero {
    background-image: url('../../img/policy-hero.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    display: block;
    border-radius: 30px;
    text-align: center;
    position: relative;
  
    h1.vertical-center {
      position: absolute;
      color: white;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  


  .li-wrapper {
    background-color: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    text-align: left;
    border-radius: 22px;
    padding: 15px;
    box-shadow: 0 0px 13px #9999993b;
  }

