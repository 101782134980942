@import "../../styles/mixins";

.demo-hero {
  background-image: url(../../img/DemoHero.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  display: block;
  border-radius: 30px;
  text-align: center;
  position: relative;

  h1.vertical-center {
    position: absolute;
    color: white;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
  }
}

.demo-li {
  background-color: #fff;
  height: 100%;
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
  border-radius: 40px;
  padding: 60px 35px;
  box-shadow: 0 0px 13px #9999993b;

  h3 {
    color: #2c78ff;
    line-height: 1.8rem;
  }

  p {
    margin-bottom: 0px;
  }
}

.column-bg {
  background-color: #fef7f2;
  border-radius: 22px;
}

.demo-content-wrapper {
  padding: 30px;

  h3 {
    color: #fd7800;
  }
  @include tablet {
    text-align: center;
  }
  .orange-btn.phone-center,
  .blue-btn.phone-center {
    display: inline-block;
  }
}

.no-shadow {
  box-shadow: none;
}

.no-shadow:hover {
  box-shadow: 0 9px 13px rgba(153, 153, 153, 0.507);
}

.blue-bg {
  background-color: #f8faff;
  border-radius: 22px;

  .h-blue {
    h3 {
      color: #2c78ff;
    }
  }
}

.eop-cta-wrapper {
  background-color: #2c78ff;
  vertical-align: middle;
  padding: 20px 0;

  .demo-cta-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include tablet {
      flex-direction: column;
    }

    .cta-text-wrapper {
      @include tablet {
        text-align: center;
      }
      p {
        font-size: 18px;
        font-weight: 700;
        padding-right: 10px;
        color: #fff;
      }
    }
  }
}

.demo-cta {
  background-image: linear-gradient(
    to right,
    #2c78ff 0%,
    #91b9ff 51%,
    #2c78ff 100%
  );
}
.demo-cta {
  padding: 10px 18px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 7px 32px #91b9ff9f;
  border-radius: 50px;
  display: block;
  text-decoration: none;
  width: fit-content;
}

.demo-cta:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.cta-wrapper {
  padding: 15px 0px;
  text-align: right;
}


.li-wrapper {
  background-color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: left;
  border-radius: 22px;
  padding: 40px 35px;
  box-shadow: 0 0px 13px #9999993b;

  .blog-subtitle{
    font-size: 20px;
    font-weight: 600;
    color: black;
  }

  p {
    margin-bottom: 0px;
  }

  strong{
    margin-top: 5px;
  }
}

.tab-flex-direction {
  @include tablet {
    flex-direction: column;
    align-items: center;
  }
}

.tab-mb-20 {
  @include tablet {
    margin-bottom: 20px;
  }
  @include md-mobile {
    margin-bottom: 0px;
  }
}

.demo-img-wrapper {
  @include tablet {
    text-align: center;
  }
}

.server-btn.orange-btn {
  width: 100%;
}