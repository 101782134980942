.admin-features-hero {
  background-image: url(../../img/about-banner.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 300px;
  display: block;
  border-radius: 30px;
  text-align: center;
  position: relative;

  h1.vertical-center {
    position: absolute;
    color: white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.feature-li {
  background-color: #fff;
  height: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: stretch;
  text-align: center;
  border-radius: 40px;
  padding: 60px 35px;
  box-shadow: 0 0px 13px #9999993b;

  img {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }

  h5 {
    color: #2c78ff;
    font-weight: 400;
  }
}
